<template>
    <b-card>
     <div>
        <div class="table-render-skeleton" v-show="loading.first">
          <b-skeleton-table :columns="schema.length || 10" :table-props="{}" />
        </div>
        <div v-show="!loading.first" class="table-enviaflex">
        <table-render 
        :schema="schema" 
        :rows="rows" 
        :loading="loading.account" 
        :actions="actions"
        >
          <template #is_active="scope">
            <i class="fa fa-circle text-success" :class="{'text-danger': !scope.rowdata.is_active}"></i>
            <span>&nbsp;{{scope.rowdata.is_active ? 'Activa' : 'Inactiva'}}</span>
          </template>
        </table-render>
        </div>
        <pagination
          v-if="!loading.first"
          :pagination="pagination"
          :noDigits="false"
          :showSize="true"
          :disabled="loading.account"
        />
      </div>
      <modal-create-flex-account id="modalEditFlex" :selectedId="selectedId" @putUpdateAccountFlex="putUpdateAccountFlex" :loadingEdit="loadingEdit"/>
    </b-card>
</template>
<script>
import { mapGetters } from 'vuex'
import ModalCreateFlexAccount from './ModalCreateFlexAccount.vue'
export default {
  components: { ModalCreateFlexAccount },
  props: ['loadingEdit'],
  data() {
    return {
      rows: [],
      schema: [],
      actions: [],
      loading: {
        first: true,
        account: false
      },
      pagination: {
        page: 1,
        limit: 20,
        total: 0
      },
      selectedId: null
    }
  },
  mounted() {
    this.setInitialData()
    this.getAccountList()
  },
  computed: {
    ...mapGetters({
      accounts: 'getAccountsFlex',
      generalLoading: 'getLoading'
    })
  },
  watch: {
    'generalLoading': {
      handler () {
        this.loading.account = !!this.generalLoading.getAccountsFlex
      },
      deep: true
    },
    accounts(value) {
      this.rows = value.data
      this.pagination.total = value.pagination.total
      this.loading.first = false
    },
    'pagination.page'() {
      this.getAccountList()
    },
    'pagination.limit'() {
      if (this.pagination.page === 1) this.getAccountList()
    }
  },
  methods: {
    setInitialData() {
      this.schema = [
        { label: 'Nombre', key: 'name', doubleDash: true },
        { label: 'Código', key: 'code' },
        { label: 'Fecha creación', key: 'created_at', doubleDash: true },
        { label: 'Estado cuenta', key: 'is_active', useSlot: true }
      ]
      if (['paula.barrera@enviame.io', 'hector.guamanquispe@enviame.io', 'gonzalo.bustos@enviame.io', 'cinthia.garrido@enviame.io', 'brahyan.martinez@enviame.io', 'ricardo.carrasco@enviame.io', 'franco.oropel@enviame.io', 'rodrigo.echeverria@enviame.io', 'darwin.acuna@enviame.io', 'yolanda.leguizamon@enviame.io', 'jose.rojas@enviame.io', 'vanessa.cordero@enviame.io', 'makarena.araya@enviame.io'].includes(this.$session.get('cas_user').email)) {
        this.schema.push({ label: 'Acciones', key: 'actions', class: ['text-center'] })
      }

      this.actions = [
        { action: id => this.openModalEdit(id), icon: 'Edit2Icon', color: 'primary', text: 'Editar Cuenta' }
      ]
    },
    getAccountList() {
      const data = { 
        name: 'getAccountsFlex',
        queryParams: {limit: this.pagination.limit, page: this.pagination.page, parent_id: this.$route.params.id }
      }
      this.$store.dispatch('fetchService', data)
    },
    openModalEdit(id) {
      this.selectedId = id
      this.$bvModal.show('modalEditFlex')
    },
    putUpdateAccountFlex(data) {
      this.$emit('putUpdateAccountFlex', data)
    }
  }
}
</script>
<style>
.table-enviaflex .table-render-skeleton {
  overflow-x: hidden !important;
}
</style>