<template>
  <b-modal
    v-bind="$attrs"
    v-on="$listeners"
    modal-class="dialog-900 modal-background--multi-color"
    centered
    hide-footer
    @show="onShow"
    @hide="onClose"
    >
      <template #modal-title>
        <h3>{{title}}</h3>
      </template>
      <div v-if="!edit">
        <div :class="{'hidden': !isHidden}">
          <ul>
            <li><span >Por favor, proporcione informacion detallada del nuevo courier y la cuenta a crear.</span></li>
          </ul>
          <form-render
          :form.sync="form"
          :fields="fields"
          ref="formRenderFlex"
          :key="formRenderFlex"
          >
          <template #labelText>
            <ul class="mb-0">
              <li><span >Seleccione la acción que desea tomar con respecto a la cuenta que se creará a continuación: <span class="text-danger"> *</span></span></li>
            </ul>
          </template>
            <template #warehouses>
              <div class="mr-1">
                <form-render
                :form.sync="formWare"
                :fields="fieldsWare"
                ref="formRenderWarehouse"
                :key="formRenderWarehouse"
                >
                <template #btnplus>
                  <b-button v-b-tooltip.hover title="Añadir combinación de bodega" :disabled="!canAddWarehouses" variant="outline-primary" @click="addWarehouses"><feather-icon icon="PlusIcon"/></b-button>
                </template>
                <template v-for="item in btnWare" v-slot:[item.name]>
                  <b-button variant="outline-danger" :key="item.name" @click="deleteWarehouse(item.name)"><feather-icon icon="TrashIcon"/></b-button>
                </template>
                </form-render>
              </div>
            </template>
          </form-render>
          <div class="d-flex justify-content-end mt-2 ">
            <b-button variant="outline-warning" @click.prevent="ok">
              {{$t('Siguiente')}}
            </b-button>
          </div>
        </div>
        <div :class="{'hidden': isHidden}">
          <form-render
          :form.sync="formRules"
          :fields="fieldsRules"
          ref="formRenderRules"
          :key="formRenderRules"
          >
          </form-render>
          <div class="d-flex justify-content-end mt-2 ">
            <b-button variant="warning" @click.prevent="onAccept" :disabled="loading">
              <b-spinner v-show="loading" small></b-spinner>
              {{$t('Finalizar')}}
            </b-button>
          </div>
        </div>
        <ul class="nav justify-content-center mt-2" @click="onNext">
          <li class="nav-item">
            <a class="nav-link active" aria-current="page">
              <feather-icon icon="CircleIcon" :fill="!isHidden ? 'none' : '#F18805'" class="text-warning"/>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link">
              <feather-icon icon="CircleIcon" :fill="isHidden ? 'none' : '#F18805'" class="text-warning"/>
            </a>
          </li>
        </ul>
      </div>
      <div v-if="edit">
        <div class="custom-switch mt-1 mb-2 d-flex">
          <div>
            <b-form-checkbox
            id="is_active"
            v-model="formEdit.is_active"
            @change="onChangeSwitch"
            switch>
            </b-form-checkbox>
          </div>
          <div>
            <span class="font-weight-bold">{{ account.is_active ? 'Cuenta Activa' : 'Cuenta Inactiva' }}</span>
            <span class=""><small>&nbsp;{{ account.is_active ? '(Al desactivar la vinculación Flex, la cuenta no estará sujeta a la Regla Mkp).' : '(Al activar la vinculación Flex, la cuenta estará sujeta a la Regla Mkp, por lo tanto deberá seleccionar una opción)'}}</small></span>
          </div>
        </div>
        <form-render
          :form.sync="formEdit"
          :fields="fieldsEdit"
          ref="formRenderEditFlex"
          :key="formRenderEditFlex"
          >
            <template #warehouses>
              <div class="mr-1" v-show="showWarehouses">
                <form-render
                :form.sync="formWare"
                :fields="fieldsWare"
                ref="formRenderWarehouse"
                :key="formRenderWarehouse"
                >
                <template #btnplus>
                  <b-button v-b-tooltip.hover title="Añadir combinación de bodega" :disabled="!!(!canAddWarehouses || notAdd)" variant="outline-primary" @click="handleAddWarehouse"><feather-icon icon="PlusIcon"/></b-button>
                </template>
                <template v-for="item in btnWare" v-slot:[item.name]>
                  <b-button variant="outline-danger" :key="item.name" @click="deleteWarehouse(item.name)"><feather-icon icon="TrashIcon"/></b-button>
                </template>
                </form-render>
              </div>
            </template>
          </form-render>
          <b-alert show variant="warning" class="d-flex my-2">
            <span class="my-1 ml-1 d-flex justify-content-center align-items-center"><feather-icon icon="AlertTriangleIcon" size="36"/></span>
            <span class="p-1 text-secondary"><small>Es importante tener en consideración que cualquier cambio en la configuración de la regla y bodega podría afectar la operación actual. Si se requiere una configuración diferente, se recomienda crear una nueva cuenta para evitar interrupciones.</small></span>
          </b-alert>
          <div class="d-flex justify-content-end mt-2 ">
            <b-button variant="warning" @click.prevent="onAcceptEdit" :disabled="loadingEdit || !showWarehouses">
              <b-spinner v-show="loadingEdit" small></b-spinner>
              {{$t('Guardar')}}
            </b-button>
          </div>
      </div>
  </b-modal>
</template>
<script>
import { mapGetters } from 'vuex'
import BaseServices from '@/store/services/index'

export default {
  props: ['loading', 'loadingEdit', 'selectedId', 'clearBtn'],
  data() {
    return {
      form: {},
      fields: [],
      formWare: {},
      fieldsWare: [],
      btnWare: [],
      formRules: {},
      fieldsRules: [],
      formEdit: {},
      fieldsEdit: [],
      isHidden: true,
      formRenderWarehouse: 0,
      formRenderFlex: 0,
      formRenderRules: 0,
      formRenderEditFlex: 0,
      allWarehouses: [],
      allOwns: [],
      selectedWarehouses: [],
      selectedOwns:[],
      organization_id: 0,
      account: {},
      edit: false,
      rules: {
        mkp: [],
        own: []
      },
      notAdd: false,
      baseService: new BaseServices(this),
      ownId: null
    }
  },
  computed: {
    ...mapGetters({
      accounts: 'getAccountsFlex',
      warehouses: 'getWarehousesApiPlatform'
    }),
    canAddWarehouses() {
      const fieldTypes = ['seller', 'own']

      for (const type of fieldTypes) {
        const fieldKeys = this.fieldsWare.filter(field => field.name.startsWith(type))
        const formKeys = Object.keys(this.formWare).filter(key => key.startsWith(type))

        if (fieldKeys.length !== formKeys.length) {
          return false
        }
      }

      return true
    },
    showWarehouses() {
      return this.allWarehouses.length > 0 && this.allOwns.length > 0
    },
    title() {
      return this.edit ? `Editar Cuenta - ${this.account.name}` : 'Añadir Courier y Nueva Cuenta'
    }
  },
  watch: {
    selectedId(val) {
      this.edit = !!val
      this.onShow()
    },
    'formWare': {
      handler () {
        if (this.formWare?.seller) this.selectedWarehouses = [...this.formWare['seller']]
        if (this.formWare?.own) this.selectedOwns = [this.formWare['own']]

        const last = this.fieldsWare[this.fieldsWare.length - 1]?.name.split('_')[1] ?? 0
        for (let index = 1; index <= last; index++) {
          if (!!this.formWare[`seller_${index}`]) this.selectedWarehouses = [...this.selectedWarehouses, ...this.formWare[`seller_${index}`]]
          if (!!this.formWare[`own_${index}`]) this.selectedOwns = [...this.selectedOwns, this.formWare[`own_${index}`]]
        }
      },
      deep: true
    }
  },
  methods: {
    onShow() {
      this.edit = !!this.selectedId
      if (this.edit) {
        this.account = this.accounts.data.find((item) => item.id === this.selectedId)
        this.setInitialDataEdit()
        this.notAdd = !this.account.is_active
      } else {
        this.setInitialData()
      }
    },
    setInitialData() {
      this.fields = [
        { fieldType: 'FieldInput', name: 'carrier_code', label: this.$t('Courier'), containerClass: 'col-sm-12 col-md-6 container-info', disabled: true },
        { fieldType: 'FieldInput', name: 'contract', label: this.$t('Tipo de contrato'), containerClass: 'col-sm-12 col-md-6 container-info', disabled: true },
        { fieldType: 'FieldInput', name: 'name', label: this.$t('Nombre de cuenta'), containerClass: 'col-sm-12 col-md-6 container-info', validation: 'required' },
        { fieldType: 'FieldInput', name: 'code', label: this.$t('Código'), containerClass: 'col-sm-12 col-md-6 container-info', validation: 'required' },
        { fieldType: 'FieldSelect', name: 'seller_id', label: this.$t('ID Seller'), containerClass: 'col-sm-12 col-md-6 container-info mt-1', validation: 'required', change: this.changeSeller, searchOnType: { fx: val => this.searchShiperFilter(val, this.$route.params.id, this.accounts.data), nChar: 3, debounce: 300, allowSearchAsValue: true, persistSearch: true } },
        { fieldType: 'FieldSelect', name: 'own_id', label: this.$t('ID cuenta propia'), containerClass: 'col-sm-12 col-md-6 container-info mt-1', useLabel: true, clearable: true, validation: 'required', disabled: true, change: this.changeOwn, searchOnType: { fx: val => this.searchShiperFilter(val, this.ownId), nChar: 3, debounce: 300, allowSearchAsValue: true, persistSearch: true } },
        { useSlot: true, name: 'labelText', containerClass: 'col-12 container-info mt-2 mb-0' },
        {
          fieldType: 'FieldRadio',
          name: 'warehouses_config',
          containerClass: 'col-12 container-info mt-0',
          validation: 'required',
          align: 'h',
          change: this.onChangeWarehouses,
          customRadio: true,
          options: [{ id: 'combination', text: 'Configurar combinación de bodegas', class: 'col-6 mt-1' }, { id: 'own', text: 'Emplear ID bodega cuenta propia por defecto', class: 'col-6 mt-1' }]
        }
      ]
      this.form.warehouses_config = { id: 'combination', text: 'Configurar combinación de bodegas' }
      this.onChangeWarehouses(null, this.form.warehouses_config)
      this.fieldsRules = [
        { fieldType: 'FieldInput', name: 'seller_id', label: this.$t('ID Seller'), containerClass: 'col-sm-12 col-md-8 container-info', disabled: true },
        { fieldType: 'FieldSelect', name: 'mkp_rule', label: this.$t('Regla mkp'), containerClass: 'col-sm-12 col-md-8 container-info', validation: 'required', aditionalLabel: {text: '(reglas disponibles del mkp que el seller puede utilizar).', type: 'primary'}, footer: { text: 'ACTUALIZAR LISTA REGLA MKP', onClick: this.reloadRules } },
        { name: 'space', useSlot: true, containerClass: 'col-sm-12 container-info pt-1 border-top mt-2' },
        { fieldType: 'FieldInput', name: 'own_id', label: this.$t('ID cuenta propia'), containerClass: 'col-sm-12 col-md-12 container-info', disabled: true },
        { fieldType: 'FieldSelect', name: 'own_rule', label: this.$t('Regla cuenta propia'), containerClass: 'col-sm-12 col-md-12 container-info', validation: 'required' }
      ]
      this.form.carrier_code = 'FLEX'
      this.form.contract = 'EnvíaFlex'
      this.getShipper(this.$route.params.id, 'seller_id', this.accounts.data)
    },
    setInitialDataEdit() {
      this.fieldsEdit = [
        { fieldType: 'FieldInput', name: 'name', label: this.$t('Nombre de la cuenta'), containerClass: 'col-12 container-info' },
        { fieldType: 'FieldInput', name: 'carrier_code', label: this.$t('Courier'), containerClass: 'col-sm-12 col-md-6 container-info mt-1', disabled: true },
        { fieldType: 'FieldInput', name: 'contract', label: this.$t('Tipo de contrato'), containerClass: 'col-sm-12 col-md-6 container-info mt-1', disabled: true },
        { name: 'space', useSlot: true, containerClass: 'col-sm-12 container-info pt-1 border-top mt-2' },
        { fieldType: 'FieldInput', name: 'seller_id', label: this.$t('ID Seller'), containerClass: 'col-sm-12 col-md-6 container-info mt-1', validation: 'required', disabled: true },
        { fieldType: 'FieldInput', name: 'own_id', label: this.$t('ID cuenta propia'), containerClass: 'col-sm-12 col-md-6 container-info mt-1', validation: 'required', disabled: true },
        { fieldType: 'FieldSelect', name: 'mkp_rule', label: this.$t('Regla mkp'), useLabel: true, containerClass: 'col-sm-12 col-md-6 container-info mt-1', validation: 'required', footer: { text: 'ACTUALIZAR LISTA REGLA MKP', onClick: () => this.reloadRules(true) } },
        { fieldType: 'FieldSelect', name: 'own_rule', label: this.$t('Regla cuenta propia'), useLabel: true, containerClass: 'col-sm-12 col-md-6 container-info mt-1', validation: 'required' },
        { name: 'space', useSlot: true, containerClass: 'col-sm-12 container-info pt-1 border-top mt-2' },
        { name: 'warehouses', containerClass: 'col-12 my-1', useSlot: true }
      ]
      this.getValuesForEdit()
      this.formEdit.name = this.account?.name
      this.formEdit.is_active = this.account?.is_active
      this.formEdit.carrier_code = this.account?.carrier_code?.toUpperCase()
      this.formEdit.contract = this.account?.contract_type?.toUpperCase()
      this.formEdit.seller_id = this.account?.shipper_id
      this.formEdit.own_id = this.account?.customization?.shipper_id
      this.setInitialFormEdit()
      setTimeout(() => {
        this.getWarehousesEdit()
      }, 1000)
    },
    setInitialFormEdit() {
      this.onChangeWarehouses(null, {id: 'combination'})
      this.formEdit.name = this.account?.name
      this.formEdit.mkp_rule = this.rules.mkp.find((item) => item.id === Number(this.account.customization.mkp_rule_code))
      this.formEdit.own_rule = this.rules.own.find((item) => item.id === Number(this.account.customization.own_rule_code))
      this.setWarehouses()
      this.formRenderEditFlex++
    },
    warehousesFields() {
      this.fieldsWare = []
      this.btnWare = []
      this.fieldsWare = [
        { fieldType: 'FieldSelect', name: 'seller', label: this.$t('ID bodega seller'), multiple: true, useLabel: true, clearable: true, disabled: true, containerClass: 'col-6', validation: 'required' },
        { fieldType: 'FieldSelect', name: 'own', label: this.$t('ID bodega cuenta propia'), useLabel: true, clearable: true, disabled: true, containerClass: 'col-5', validation: 'required' },
        { name: 'btnplus', containerClass: 'col-1 d-flex align-items-end', useSlot: true }
      ]
    },
    async handleAddWarehouse() {
      const warehouse = await this.$refs.formRenderWarehouse.onlyCheckForm()
      if (warehouse) this.addWarehouses()
    },
    addWarehouses() {  
      const index = this.btnWare.length === 0 ? 1 : Number(this.btnWare[this.btnWare.length - 1].name.split('_')[1]) + 1
      this.btnWare = [
        ...this.btnWare,
        { name: `btnremove_${index}`, containerClass: 'col-1 d-flex align-items-end mt-1', useSlot: true }
      ]
      this.fieldsWare = [
        ...this.fieldsWare,
        { fieldType: 'FieldSelect', name: `seller_${index}`, multiple: true, label: this.$t('ID bodega seller'), containerClass: 'col-6 mt-1', validation: 'required' },
        { fieldType: 'FieldSelect', name: `own_${index}`, label: this.$t('ID bodega cuenta propia'), containerClass: 'col-5 mt-1', validation: 'required' },
        this.btnWare[this.btnWare.length - 1]
      ]
      const filterWare = this.allWarehouses.filter((item) => !this.selectedWarehouses.some((sw) => sw.id === item.id))
      const filterOwn = this.allOwns
      this.setSelectOptions(this.fieldsWare, `seller_${index}`, {options: filterWare})
      this.setSelectOptions(this.fieldsWare, `own_${index}`, {options: filterOwn})
      // bloquea los anteriores fields de warehouses
      this.fieldsWare.forEach(item => {
        if (![`seller_${index}`, `own_${index}`].includes(item.name)) this.setEnableWarehouses(item.name, this.fieldsWare, true)
      })
      this.formRenderWarehouse++
    },
    deleteWarehouse(name) {
      const index = Number(name.split('_')[1])
      this.btnWare = this.btnWare.filter((field) => field.name !== `btnremove_${index}`)
      this.fieldsWare = this.fieldsWare.filter((field) => ![`seller_${index}`, `own_${index}`, `btnremove_${index}`].includes(field.name))
      const idsToRemoveSet = new Set(this.formWare[`seller_${index}`].map(item => item.id))
      this.selectedWarehouses =  this.selectedWarehouses.filter(item => !idsToRemoveSet.has(item.id))
      if (!!this.formWare[`seller_${index}`]) delete this.formWare[`seller_${index}`]
      if (!!this.formWare[`own_${index}`]) delete this.formWare[`own_${index}`]
      // desbloquea el anterior field de warehouse
      this.fieldsWare.forEach(item => {
        if (index > 1 && [`seller_${index - 1}`, `own_${index - 1}`].includes(item.name)) this.setEnableWarehouses(item.name, this.fieldsWare, false)
        if (index === 1 && ['seller', 'own'].includes(item.name)) this.setEnableWarehouses(item.name, this.fieldsWare, false)
      })
    },
    onChangeWarehouses(_, value) {
      this.fieldsWare = []
      this.btnWare = []
      this.formWare = {}
      const index = this.fields.findIndex((field) => ['own_only', 'warehouses'].includes(field.name))
      if (index !== -1) this.fields.splice(index, 1)
      //si se obtiene el valor:'combination' se agrega el formulario warehouses caso contrario el select 'own_only'
      if (value.id === 'own') {
        this.fields.push({ fieldType: 'FieldSelect', name: 'own_only', disabled: true, useLabel: true, clearable: true, label: this.$t('ID bodega cuenta propia'), containerClass: 'col-12 mt-1', validation: 'required' })
        if (this.allOwns.length > 0) this.setSelectOptions(this.fields, 'own_only', { options: this.allOwns })
      } else {
        this.fields.push({ name: 'warehouses', containerClass: 'col-12 my-1', useSlot: true })
        this.warehousesFields()
        if (this.allWarehouses.length > 0) this.setSelectOptions(this.fieldsWare, 'seller', { options: this.allWarehouses })
        if (this.allOwns.length > 0) this.setSelectOptions(this.fieldsWare, 'own', { options: this.allOwns })
      }
    },
    setSelectOptions (fields, name, { options }) {
      const index = fields.findIndex(el => el.name === name)
      if (index !== -1) fields[index].options = options; fields[index].disabled = false
    },
    cleanFormW() {
      delete this.form.mkp_rule
      delete this.form.own_id
      delete this.formWare.seller
      delete this.formWare.own
      this.ownId = null
      this.setSelectOptions(this.fieldsWare, 'seller', { options: [] })
      this.setSelectOptions(this.fieldsWare, 'own', { options:[] })
      this.deleteFormWare()
    },
    changeSeller (name, value) {
      this.cleanFormW()
      if (!value) return
      // cuando cambia el sellerId llama a los shipper por orgnizatrion y
      // las reglas para le siguiente formulario
      this.organization_id = value.id
      this.setSkeletonWarehouses('own_id', this.fields, true)

      if (!this.edit && this.fields.length) {
        this.ownId = value?.id
        this.getShipper(value?.id, 'own_id')
      }

      const services = [
        { name: 'getAdvancedRulesByOrganization', params: {organization_id: value?.id }, queryParams: { page: 1, limit: 999, rule_classification_id:1 }, onSuccess: (resp) => { this.setSelectOptions(this.fieldsRules, 'mkp_rule', { options: resp.rows.map((item) => ({...item, text: `${item.id} - ${item.name}`})) }); this.formRenderRules++ }, hideAlert: true}
      ]
      
      if (this.form?.warehouses_config?.id === 'combination') {
        this.setSkeletonWarehouses('seller', this.fieldsWare, true)
        const onSuccessPuccon = (data) => {
          this.allWarehouses = data.rows.map((item) => ({ ...item, text: `${item.id} - ${item.name}` })) ?? []
          this.setSelectOptions(this.fieldsWare, 'seller', { options: this.allWarehouses })
          this.setSkeletonWarehouses('seller', this.fieldsWare, false) 
        }
        services.push({ name: 'getWarehousesApiPlatformPucon', params: {shipper_id: value?.id }, onSuccess: onSuccessPuccon, hideAlert: true})
      }
      this.$store.dispatch('fetchMultipleServices', { services })
    },
    getShipper(value, name, disable) {
      this.baseService.callService('getShippersByOrganization', { page: 1, paginate_by: 10 }, {organization_id: value })
        .then(resp => {
          this.setSelectOptions(this.fields, name,
            { 
              options: resp.data.map((item) => ({
                ...item, name: item.name1, text: `${item.id} - ${item.name1}`,
                disabled: disable ? disable.some((acc) => acc.shipper_id === item.id) : false 
              }))
            }
          )
          this.setSkeletonWarehouses(name, this.fields, false)
          this.formRenderFlex++
        })
        .catch(err => {
          return err
        })
    },
    searchShiperFilter (value, id, disable) { // Descomentar
      const params = {
        organization_id: id
      }
      const queryParams = {
        'name__or__code__like': value
      }
      return this.baseService.callService('getShippersByOrganization', queryParams, params)
        .then(resp => {
          const response = resp.data.map(item => ({
            ...item, name: item.name1, text: `${item.id} - ${item.name1}`,
            disabled: disable ? disable.some((acc) => acc.shipper_id === item.id) : false 
          }))
          return response
        })
        .catch(err => {
          return err
        })
    },
    changeOwn(name, value) {
      this.deleteFormWare()
      delete this.formWare.own
      if (!value) return
      const services = [ 
        { name: 'getWarehousesApiPlatformPucon', params: {shipper_id: value?.id }, hideAlert: true},
        { name: 'getAdvancedRulesByOrganization', params: {organization_id: this.organization_id }, queryParams: { page: 1, limit: 999, rule_classification_id:1, only_sellers: true, shipper_id: value?.id }, onSuccess: (resp) => { this.setSelectOptions(this.fieldsRules, 'own_rule', { options: resp.rows.map((item) => ({...item, text: `${item.id} - ${item.name}`})) }) }, hideAlert: true}
      ]
      if (this.form?.warehouses_config?.id === 'combination') {
        this.setSkeletonWarehouses('own', this.fieldsWare, true)
        const onSuccess = (data) => {
          this.allOwns = data.rows.map((item) => ({ ...item, text: `${item.id} - ${item.name}` })) ?? []
          this.setSelectOptions(this.fieldsWare, 'own', { options: this.allOwns })
          this.setSkeletonWarehouses('own', this.fieldsWare, false)
          this.formRenderWarehouse++
        }
        services[0].onSuccess = onSuccess
      } else {
        this.setSkeletonWarehouses('own_only', this.fields, true)
        const onSuccess = (data) => {
          this.allOwns = data.rows.map((item) => ({ ...item, text: `${item.id} - ${item.name}` })) ?? []
          this.setSelectOptions(this.fields, 'own_only', { options: this.allOwns })
          this.setSkeletonWarehouses('own_only', this.fields, false)
          this.formRenderFlex++
        }
        services[0].onSuccess = onSuccess
      }      
      this.$store.dispatch('fetchMultipleServices', { services })
    },
    deleteFormWare() {
      const keysToRemove = Object.keys(this.formWare).filter(key => key.startsWith('own_') || key.startsWith('seller_'))
      keysToRemove.forEach(key => {
        delete this.formWare[key]
      })
      this.btnWare.forEach(field => {
        this.deleteWarehouse(field.name)
      })
    },
    setSkeletonWarehouses(target, fields, value) {
      const index = fields.findIndex(el => el.name === target)
      if (index !== -1) fields[index].useSkeleton = value
    },
    setEnableWarehouses(target, fields, value) {
      const index = fields.findIndex(el => el.name === target)
      if (index !== -1) fields[index].disabled = value
    },
    async ok() {
      const flex = await this.$refs.formRenderFlex.onlyCheckForm()
      if (this.form.warehouses_config.id === 'combination') {
        const warehouse = await this.$refs.formRenderWarehouse.onlyCheckForm()
        if (!warehouse) return
      }
      if (!flex) return 
      this.formRules.seller_id = this.form.seller_id?.id
      this.formRules.own_id = this.form.own_id?.id
      this.isHidden = !this.isHidden
      this.formRenderRules++
    },
    async onNext() {
      await this.ok()
    },
    async onAccept() {
      //validacion de todos los formularios
      const flex = await this.$refs.formRenderFlex.onlyCheckForm()
      const rules = await this.$refs.formRenderRules.onlyCheckForm()
      if (!flex) return
      if (!rules) return
      if (this.form.warehouses_config.id === 'combination') {
        const warehouse = await this.$refs.formRenderWarehouse.onlyCheckForm()
        if (!warehouse) return
      }
      //se arma los warehouses dependiendo de la configuracion
      let warehouses = []
      if (this.form.warehouses_config.id === 'own') {
        warehouses = this.allWarehouses?.map((item) => ({ mkp_warehouse: item.code, own_warehouse: this.form.own_only?.code }))
      } else {
        warehouses = this.getWarehouseSelected()
      }
      //payload
      const data = {
        customization: {
          warehouses,
          shipper_id: `${this.formRules?.own_id}`,
          mkp_rule_code: `${this.formRules?.mkp_rule?.id}`,
          own_rule_code: `${this.formRules?.own_rule?.id}`
        },
        name: this.form?.name,
        code: this.form?.code,
        carrier_code: this.form?.carrier_code?.toLowerCase(),
        contract_type: 'enviaflex',
        shipper_id: this.form?.seller_id?.id,
        parent_id: Number(this.$route.params.id),
        is_active: true
      }
      this.$emit('postCreateNewAccountFlex', data)
    },
    async onAcceptEdit() {
      const formEdit = await this.$refs.formRenderEditFlex.onlyCheckForm()
      if (!formEdit) return
      const warehouse = await this.$refs.formRenderWarehouse.onlyCheckForm()
      if (!warehouse) return

      const data = {
        customization: {
          warehouses: this.getWarehouseSelected(),
          shipper_id: `${this.account?.customization?.shipper_id}`,
          mkp_rule_code: `${this.formEdit?.mkp_rule?.id}`,
          own_rule_code: `${this.formEdit?.own_rule?.id}`
        },
        account: this.account,
        id: this.account.id,
        name: this.formEdit?.name,
        code: this.account?.code,
        carrier_code: this.formEdit?.carrier_code?.toLowerCase(),
        contract_type: this.formEdit?.contract?.toLowerCase(),
        shipper_id: this.account?.shipper_id,
        parent_id: Number(this.$route.params.id),
        is_active: this.formEdit?.is_active
      }
      this.$emit('putUpdateAccountFlex', data)
    },
    getWarehouseSelected() {
      let warehouses = []
      const last = this.fieldsWare[this.fieldsWare.length - 1]?.name.split('_')[1] ?? 0
      warehouses = [...this.formWare?.seller?.map((item) => ({ mkp_warehouse: item.code, own_warehouse: this.formWare?.own?.code }))]
      for (let index = 1; index <= last; index++) {
        warehouses = [...warehouses, ...this.formWare[`seller_${index}`].map((item) => ({ mkp_warehouse: item.code, own_warehouse: this.formWare[`own_${index}`].code }))]
      }
      return warehouses
    },
    getValuesForEdit() {
      this.setSkeletonWarehouses('own_rule', this.fieldsEdit, true)
      this.setSkeletonWarehouses('mkp_rule', this.fieldsEdit, true)

      const onSuccess = () => {
        this.setSkeletonWarehouses('own_rule', this.fieldsEdit, false)
        this.setSkeletonWarehouses('mkp_rule', this.fieldsEdit, false)
        this.setInitialFormEdit()
      }
      const services = [
        { name: 'getAdvancedRulesByOrganization', params: {organization_id: this.account?.shipper_id }, queryParams: { page: 1, limit: 999, rule_classification_id:1, only_sellers: true, shipper_id: this.account?.customization?.shipper_id }, onSuccess: (resp) => { this.setSelectOptions(this.fieldsEdit, 'own_rule', { options: this.rules.own = resp.rows.map((item) => ({...item, text: `${item.id} - ${item.name}`}))}); this.formRenderEditFlex++ }, hideAlert: true},
        { name: 'getAdvancedRulesByOrganization', params: {organization_id: this.account?.shipper_id }, queryParams: { page: 1, limit: 999, rule_classification_id:1 }, onSuccess: (resp) => { this.setSelectOptions(this.fieldsEdit, 'mkp_rule', { options: this.rules.mkp = resp.rows.map((item) => ({...item, text: `${item.id} - ${item.name}`})) }); this.formRenderEditFlex++ }, hideAlert: true},
        { name: 'getWarehousesApiPlatformPucon', params: {shipper_id: this.account?.customization?.shipper_id }, onSuccess: (data) => { this.setSelectOptions(this.fieldsWare, 'own', { options: this.allOwns = data.rows.map((item) => ({ ...item, text: `${item.id} - ${item.name}` })) ?? [] }); this.formRenderWarehouse++ }, hideAlert: true}
      ]
      
      this.$store.dispatch('fetchMultipleServices', { services, onSuccess })
    },
    getWarehousesEdit() {
      const onSuccessWare = () => {
        this.setSkeletonWarehouses('seller', this.fieldsWare, false)
        this.setSkeletonWarehouses('own', this.fieldsWare, false)
        this.setInitialFormEdit()
      }
      const serviceWare = [
        { name: 'getWarehousesApiPlatformPucon', params: {shipper_id: this.account?.shipper_id }, onSuccess: (data) => { this.setSelectOptions(this.fieldsWare, 'seller', { options: this.allWarehouses = data.rows.map((item) => ({ ...item, text: `${item.id} - ${item.name}` })) ?? [] }); this.formRenderWarehouse++ }, hideAlert: true}
      ]
      this.$store.dispatch('fetchMultipleServices', { services: serviceWare, onSuccess: onSuccessWare })
    },
    setWarehouses() {
      if (this.allOwns.length === 0 || this.allWarehouses.length === 0) return
      const { warehouses } = this.account.customization
      const own_ware = [...new Set(warehouses.map((item) => item.own_warehouse))]
      const ware = []
      own_ware.forEach(el => {
        const filter = warehouses?.filter((item) => item.own_warehouse === el) ?? []
        ware.push(filter)
      })
      this.formWare.seller = this.allWarehouses.filter(item => ware[0].some((wa) => wa.mkp_warehouse === item.code))
      this.formWare.own = this.allOwns.find(item => item.code === own_ware[0])
      const last = ware.length
      for (let index = 1; index < last; index++) {
        this.addWarehouses()
        this.formWare[`seller_${index}`] = this.allWarehouses.filter(item => ware[index].some((wa) => wa.mkp_warehouse === item.code))
        this.formWare[`own_${index}`] = this.allOwns.find(item => item.code === own_ware[index])
      }
      this.setDisabledFieldsEdit(this.formEdit.is_active)
    },
    onChangeSwitch(checked) {
      // si esta condicional es falsa se vuelven a setaear los valores inicales en el form
      if (!checked) {
        this.setInitialFormEdit()
      }
      this.notAdd = !checked
      // deshabilita o habilita los campos editables
      this.setDisabledFieldsEdit(checked)
    },
    setDisabledFieldsEdit(checked) {
      const last = this.fieldsWare[this.fieldsWare.length - 1]?.name.split('_')[1] ?? 0
      this.fieldsEdit = this.fieldsEdit.map((field) => (({...field, disabled: ['mkp_rule', 'own_rule', 'name'].includes(field.name) ? !checked : !!field?.disabled})))
      this.fieldsWare = this.fieldsWare.map((field) => (({...field, disabled: ['seller', 'own', `seller_${last}`, `own_${last}`].includes(field.name) ? !checked : !!field?.disabled})))
    },
    reloadRules(isEdit = false) {
      const data = {
        name: 'getAdvancedRulesByOrganization',
        params: {organization_id: isEdit ? this.formEdit.seller_id : this.form?.seller_id?.id },
        queryParams: { page: 1, limit: 999, rule_classification_id:1 },
        onSuccess: (resp) => { this.setSelectOptions(isEdit ? this.fieldsEdit : this.fieldsRules, 'mkp_rule', { options: resp.data.map((item) => ({...item, text: `${item.id} - ${item.name}`})) }); isEdit ? this.formRenderEditFlex++ : this.formRenderRules++ }, hideAlert: true
      }
      this.$store.dispatch('fetchService', data)
    },
    onClose() {
      this.form = {}
      this.formWare = {}
      this.formRules = {}
      this.isHidden = true
      this.allWarehouses = []
      this.allOwns = []
      this.$emit('clearBtn')
    }
  }
}
</script>
<style>
.custom-switch > .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #1E8E3E;
  background-color: #1E8E3E;
}
#warehouses_config > label > span {
  font-size: 14px;
}
</style>