<template>
<div>
    <b-card>
      <div v-show="!loading.first" class="table-distribution">
        <table-render :schema="schema" :rows="rows" :loading="loading.account" :actions="actions"
        id="MultiAccount">
        </table-render>
        <pagination
          v-if="!loading.first"
          :pagination="pagination"
          :noDigits="false"
          :showSize="true"
          :disabled="loading.account"
        />
      </div>
      <b-skeleton
        height="40px"
        width="100%"
        class="mt-2 mb-2 spacing-label-field"
        v-show="loading.first"
      />
      <div class="table-render-skeleton" v-show="loading.first">
        <b-skeleton-table
          :columns="schema.length || 10"
          :table-props="{}"
        />
      </div>
    </b-card>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
export default {
  name: 'multi-accounts',
  props: ['openModal', 'modalType'],
  data() {
    return {
      schema: [],
      rows: [],
      pagination: { page: 1, limit: 10, total: 0 },
      actions: [],
      loading: {
        first: true,
        account: true
      },
      keyFormRender: 0
    }
  },
  computed: {
    ...mapGetters({
      accounts: 'getCarriersOrganizationShippers'
    })
  },
  mounted() {
    this.setInitialData()
  },
  watch: {
    accounts() {
      this.rows = this.accounts.rows.filter(account => account.contract_type === 'distribucion').map(account => {
        const isActiveIcon = '<spq class="fa fa-circle text-success"></spq>'
        const isInactiveIcon = '<i class="fa fa-circle text-danger"></i>'
        const isActiveBlockeService = '<i class="fa fa-check-circle text-success"></i>'
        const starIcon = '<i class="fa fa-star text-warning"></i>'
        const disableStarIcon = '<i class="fa fa-star text-secondary"></i>'
        return {
          ...account,
          account_name: account.shipper.name,
          date_create: account.created_at ? this.fixDate(account.created_at) : '---',
          carrier_name: account.carrier.name,
          has_blocked_services: account.blocked_services.length > 0 ? isActiveBlockeService : '---',
          status_account: account.is_active ? `${isActiveIcon} Activa` : `${isInactiveIcon} Inactiva`,
          date_expiration: this.$options.filters.humanizeExpirationDate(account.validity_date) || '---',
          carrier_status: `${account.carrier?.status === 'Habilitado' ? isActiveIcon : isInactiveIcon} ${account.carrier.status}`,
          active_star: account.is_preferential ? starIcon : disableStarIcon
        }
      })
      this.loading.first = false
      this.loading.account = false
      this.pagination.page = this.accounts.page
      this.pagination.total = this.accounts.total
      this['pagination.limit'] = this.accounts.limit
    },
    'pagination.page'() {
      this.getAccount(false)
    },
    'pagination.limit'() {
      if (this.pagination.page === 1) this.getAccount(false)
    }
  },
  methods: {
    setInitialData() {
      this.schema = [
        { label: '', key: 'active_star', class: ['text-center'], useDangerHTML: true },
        { label: 'Nombre', key: 'account_name' },
        { label: 'Código', key: 'code' }, 
        { label: 'Courier', key: 'carrier_name' },
        { label: 'Estado courier', key: 'carrier_status', useDangerHTML: true },
        { label: 'Fecha creación', key: 'date_create' },
        { label: 'Fecha expiración', key: 'date_expiration' },
        { label: 'Estado cuenta', key: 'status_account', useDangerHTML: true },
        { label: 'Servicios bloqueados', key: 'has_blocked_services', useDangerHTML: true }
      ]

      if (['paula.barrera@enviame.io', 'hector.guamanquispe@enviame.io', 'gonzalo.bustos@enviame.io', 'cinthia.garrido@enviame.io', 'brahyan.martinez@enviame.io', 'ricardo.carrasco@enviame.io', 'franco.oropel@enviame.io', 'rodrigo.echeverria@enviame.io', 'darwin.acuna@enviame.io', 'yolanda.leguizamon@enviame.io', 'jose.rojas@enviame.io', 'makarena.araya@enviame.io', 'vanessa.cordero@enviame.io'].includes(this.$session.get('cas_user')?.email)) {
        this.schema.push({ label: 'Acciones', key: 'actions', class: ['text-center'] })
      }


      this.actions = [
        { action: id => this.openUpdateModal(id, this.modalType.DETAIL), icon: 'FileTextIcon', color: 'primary', text: 'Detalle Cuenta' },
        { action: id => this.openUpdateModal(id, this.modalType.EDIT), icon: 'Edit2Icon', color: 'primary', text: 'Editar Cuenta' },
        { action: id => this.confirmDeleteAccount(id), icon: 'Trash2Icon', color: 'danger', text: 'Eliminar' }
      ]

      this.getAccount()
    },
    betaFeatures() {
      this.$info('Esta funcionalidad se encuentra en desarrollo, si tienes alguna duda o sugerencia, por favor contacta con support', null, 'Beta')
    },
    fixDate(date) {
      const splitDateHours = date.split(' ')
      const splitDate = splitDateHours[0].split('-')
      return this.$options.filters.dbDateUTCToGMTlocal(`${splitDate[2]}-${splitDate[1]}-${splitDate[0]} ${splitDateHours[1]} UTC`, 'DD-MM-YYYY HH:mm:ss')
    },
    getAccount(firstRender = true) {
      if (firstRender) this.loading.first = true
      this.loading.account = true
      const queryParams = {
        shipper_id : this.$route.params.id,
        contract_type : 'distribucion',
        page: this.pagination.page,
        limit: this.pagination.limit
      }
      this.$store.dispatch('fetchCarriersOrganizationShippers', {
        queryParams, onError: () => this.loading.account = false
      })
    },
    openUpdateModal(_id, type) {
      this.openModal(this.rows.filter(({ id }) => id === _id)[0], type, 'Distribucion')
    },
    confirmDeleteAccount(_id) {
      this.$yesno(this.$t('msg-pregunta-eliminar-seleccionados'), () => this.deleteAccount(_id))
    },
    deleteAccount(_id) {
      const account = this.rows.filter(({ id }) => id === _id)[0]
      const headers = {
        'x-carrier' : account.carrier.code
      }
      this.$store.dispatch('fetchService', { name: 'deleteCarriersAccount', params: { account_id: _id }, headers,  onSuccess: this.successDeleteAccount })
    },
    successDeleteAccount() {
      this.getAccount()
      this.$success(this.$t('msg-exito-eliminar-elemento'))
    }
  }
}
</script>

<style lang='scss'>
.account-button {
  top: 5.5rem !important;
  right: 2rem !important;
  position: absolute !important;
  z-index: 9;
}
.table-distribution .table-render-skeleton {
  overflow-x: hidden !important;
}
</style>
