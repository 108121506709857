<template>

  <div>
    <div :class="['account-button float-right', classHidden]">
      <b-button :variant="btnColor" class="push-right rounded-pill" @click="createNewAccount" :key="keyFormRenderType" :disabled="loading.carriers">
        <feather-icon icon="PlusCircleIcon" /> Añadir courier y nueva cuenta
      </b-button>
      <b-button :variant="showTableHistory ? 'primary': 'outline-primary'" class="push-right rounded-pill" @click="betaFeatures" :disabled="activeLogsBtn">
        <feather-icon icon="ClockIcon" /> Historial de cambios
        <b-spinner small v-if="loading.accountLogs"/>
      </b-button>
      <table-account-logs @update="updateLoading" @close="showAmountLogs" v-if="showTableHistory"/>    
    </div>
    <div class="tabs-multiAccounts">
      <b-tabs v-model="tabIndex" @input="onTabChange">
        <b-tab lazy @click="changeFieldsSelectType(1)">
          <template #title>
            <div class="tab-title">
              <span> {{$t('Distribución')}} </span>
            </div>
          </template>
          <table-distribucion :openModal="openModal" :modalType="ENUMS"></table-distribucion>
        </b-tab>
        <b-tab lazy @click="changeFieldsSelectType(1)">
          <template #title>
            <div class="tab-title">
              <span> {{$t('Connect')}} </span>
            </div>
          </template>
          <table-pasarela :changeRuleButton="changeFieldsSelectType" :openModal="openModal" :openModalRulesUpdate="openModalRules" :modalType="ENUMS"/>
          <modal-created-rule :formTab="form"/>
        </b-tab>
        <b-tab lazy @click="changeFieldsSelectType(tabIndex)">
          <template #title>
            <div class="tab-title d-flex">
              <span> {{$t('EnvíaFlex')}} </span>
              <span class="custom-badge"><span>NUEVO</span></span>
            </div>
          </template>
          <table-enviaflex ref="tabEnviaflex" @putUpdateAccountFlex="putUpdateAccountFlex" :loadingEdit="loading.editFlex"/>
          <modal-create-flex-account id="modalCreateFlex" :loading="loading.createFlex" @postCreateNewAccountFlex="postCreateNewAccountFlex" @clearBtn="changeColor"/>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import TablePasarela from './Multi-Account/TablePasarela.vue'
import TableDistribucion from './Multi-Account/TableDistribucion.vue'
import ModalCreatedRule from './Multi-Account/ModalCreatedRule.vue'
import TableAccountLogs from './Multi-Account/TableAccountLogs.vue'
import TableEnviaflex from './Multi-Account/TableEnviaflex.vue'
import ModalCreateFlexAccount from './Multi-Account/ModalCreateFlexAccount.vue'

export default {
  name: 'multi-accounts',
  props: ['openModal'],
  components: {
    TablePasarela,
    TableDistribucion,
    ModalCreatedRule,
    TableAccountLogs,
    TableEnviaflex,
    ModalCreateFlexAccount
  },
  data() {
    return {
      showTableRules: false,
      tabIndex: 0,
      form: {},
      showBtn: true,
      keyFormRenderType: 0,
      classHidden: 'hidden',
      showTableHistory: false,
      loading: {
        accountLogs: false,
        carriers: true,
        createFlex: false,
        editFlex: false
      },
      activeLogsBtn: false,
      ENUMS: {
        CREATION: 'creation',
        EDIT: 'edit',
        DETAIL: 'detail'
      },
      btnColor: 'outline-warning'
    }
  },
  mounted() {
    const queryParams = {
      simplify: true
    }
    this.$store.dispatch('fetchService', { name: 'getCarriers', queryParams })
    this.shouldHideAccountButton()
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession',
      carriers: 'getCarriers',
      generalLoading: 'getLoading'
    })
  },
  watch: {
    'generalLoading': {
      handler () {
        this.loading.createFlex = !!this.generalLoading.postAccountFlex || !!this.generalLoading.putMkpStateRule || !!this.generalLoading.putSellerStateRule
        this.loading.editFlex = !!this.generalLoading.putAccountFlex || !!this.generalLoading.putMkpStateRule || !!this.generalLoading.putSellerStateRule
      },
      deep: true
    },
    mySession () {
      if (this.mySession.id) {
        this.shouldHideAccountButton()
      }
    },
    carriers () {
      this.loading.carriers = false
    }
  },
  methods: {
    shouldHideAccountButton() {
      if (!this.$session.get('cas_user')?.email) return
      const userEmail = this.$session.get('cas_user')?.email
      this.classHidden = ['paula.barrera@enviame.io', 'hector.guamanquispe@enviame.io', 'gonzalo.bustos@enviame.io', 'cinthia.garrido@enviame.io', 'brahyan.martinez@enviame.io', 'ricardo.carrasco@enviame.io', 'franco.oropel@enviame.io', 'rodrigo.echeverria@enviame.io', 'darwin.acuna@enviame.io', 'yolanda.leguizamon@enviame.io', 'jose.rojas@enviame.io', 'makarena.araya@enviame.io', 'vanessa.cordero@enviame.io'].includes(userEmail) ? '' : 'hidden'
    },
    betaFeatures() {
      if (['paula.barrera@enviame.io', 'hector.guamanquispe@enviame.io', 'vanessa.cordero@enviame.io', 'makarena.araya@enviame.io', 'erika.gonzalez@enviame.io', 'ricardo.carrasco@enviame.io', 'franco.oropel@enviame.io', 'rodrigo.echeverria@enviame.io'].includes(this.$session.get('cas_user')?.email)) {
        this.activeLogsBtn = true
        this.showAmountLogs(true, true)
      }
      else this.$info('Esta funcionalidad se encuentra en desarrollo, si tienes alguna duda o sugerencia, por favor contacta con support', null, 'Beta')
    },
    changeColor(color = 'outline-warning') {
      this.btnColor = color
    },
    createNewAccount() {
      if (this.tabIndex === 2) {
        this.changeColor('warning')
        return this.$bvModal.show('modalCreateFlex')
      }
      const contractType = this.tabIndex === 0 ? 'Distribucion' : 'Pasarela'
      this.openModal({
        '_timestamp': Date.now()
      }, this.ENUMS.CREATION, contractType)
    },
    changeFieldsSelectType(id) {
      if (id === 1) {
        this.showTableRules = false
        this.showBtn = true
      } else {
        this.showTableRules = true
        this.showBtn = false
      }
    },
    openModalRules(data) {
      if (data) this.form = { ...data, _timestamp: Date.now()}
      else this.form = {}
      this.$bvModal.show('modalCreateRules')
      this.$emit('modalRuleAbierto')
    },
    updateLoading() {
      this.loading.accountLogs = false
    },
    showAmountLogs (show = false, load = null) {
      if (load) this.loading.accountLogs = load
      if (!show)  this.activeLogsBtn = false
      this.showTableHistory = show
    },
    onTabChange(currentIndexTab) {
      this.$store.dispatch('dispatchIndexTabsMultiAccounts', currentIndexTab)
    },
    postCreateNewAccountFlex(data) {
      return new Promise((resolve, reject) => {
        const onSuccess = () => {
          this.$refs.tabEnviaflex.getAccountList()
          this.$bvModal.hide('modalCreateFlex')
          this.changeColor()
          this.$customSuccess({
            modalTitle: 'Añadir courier y nueva cuenta',
            title: '¡La vinculación FLEX se ha completado con éxito!',
            info: `Hemos creado la cuenta <span class="text-primary font-weight-bold">“${data.name}”</span> para que puedas comenzar a optimizar tus operaciones.`
          })
          resolve('Flex account creation successful')
        }
        //en caso de error al crear cuentas flex desactiva las reglas
        const onError = (error) => {
          const serviceDeactivate = this.getServicesRules(data, 'deactivate')
          this.$store.dispatch('fetchMultipleServices', { services: serviceDeactivate })
          this.errorNewAccount(data, error, {m: 'Añadir courier y nueva cuenta', t: '¡La vinculación FLEX no se ha completado con éxito!'})
          reject(new Error('Failed to create Flex account'))
          this.$bvModal.hide('modalCreateFlex')
        }
        const onSuccessRules = () => {
          const fecth = { 
            name: 'postAccountFlex',
            queryParams: data,
            onSuccess,
            onError, 
            hideAlert: true,
            config: { fullResponseError: true }
          }
          this.$store.dispatch('fetchService', fecth)
        }
        //servicios rules para activar
        const services = this.getServicesRules(data, 'active')
        //si es exitoso crea la cuenta flex
        this.$store.dispatch('fetchMultipleServices', { services, onSuccess: onSuccessRules })
      })
    },
    errorNewAccount(data, err, msg) {
      this.$customError({
        modalTitle: msg.m,
        title: msg.t,
        error: err.message,
        multipleOption: true,
        recallAction: () => {
          return new Promise((resolve, reject) => {
            this.postCreateNewAccountFlex(data)
              .then(resolve)
              .catch(reject)
          })
        }
      })
    },
    putUpdateAccountFlex(data) {
      return new Promise((resolve, reject) => {
        const { id, account, ...payload } = data
        const onSuccess = () => {
          this.$refs.tabEnviaflex.getAccountList()
          this.$bvModal.hide('modalEditFlex')
          this.$customSuccess({
            modalTitle: `Editar Cuenta - ${data.name}`,
            title: '¡La Cuenta FLEX se ha editado con éxito!'
          })
          resolve('Flex account creation successful')
        }
        const onError = (error) => {
          this.$store.dispatch('fetchMultipleServices', { services: this.getServicesRules(data, 'active') })
          this.errorNewAccount(data, error, {m: `Editar Cuenta - ${data.name}`, t: '¡La edición FLEX no se ha completado con éxito!'})
          reject(new Error('Failed to edit Flex account'))
          this.$bvModal.hide('modalCreateFlex')
        }
        const fecth = { 
          name: 'putAccountFlex',
          queryParams: {...payload},
          params: { account_id: id},
          onSuccess,
          onError,
          hideAlert: true,
          config: { fullResponseError: true }
        }

        const onUpdateForDeactivate = () => {
          const rest = fecth
          if (!rest.queryParams.is_active) {
            delete rest.queryParams.customization.mkp_rule_code
            delete rest.queryParams.customization.own_rule_code
          }
          this.$store.dispatch('fetchService', rest)
        }

        const onUpdateForActivate = () => {
          const { onError, ...rest} = fecth
          const error = () => {
            this.$store.dispatch('fetchMultipleServices', { services: this.getServicesRules(data, 'deactivate')})
          }
          this.$store.dispatch('fetchService', {...rest, onError: error})
        }

        let services = []
        const serviceDeactivate = []
        const serviceActivate = []

        // en caso de modificacion en las reglas
        const onUpdateAccount = () => {
          // en caso de error revierte las reglas
          const error = () => {
            this.$store.dispatch('fetchMultipleServices', { services: serviceActivate.map((item) => ({...item, method: 'deactivate'})) })
            this.$store.dispatch('fetchMultipleServices', { services: serviceDeactivate.map((item) => ({...item, method: 'active'})) })
          }
          const {onError, ...rest} = fecth
          this.$store.dispatch('fetchService', {...rest, onError: error})
        }

        // activa las reglas y en success modifica la cuenta
        const onSuccessDeactivate = () => {
          this.$store.dispatch('fetchMultipleServices', { services: serviceActivate, onSuccess: onUpdateAccount })
        }

        if (!payload.is_active) {
          //servicios rules para desactivar
          services = this.getServicesRules(data, 'deactivate')
          //si es exitoso modifica la cuenta flex
          this.$store.dispatch('fetchMultipleServices', { services, onSuccess: onUpdateForDeactivate })
        } else {
          // si de desactivada pasa a activa
          if (!account.is_active) this.$store.dispatch('fetchMultipleServices', { services: this.getServicesRules(data, 'active'), onSuccess: onUpdateForActivate })
          
          // si esta activa y cambia alguna regla
          if (account.is_active) {
            // se desactivan las regla anterior si es que cambia de valor
            if (account.customization.mkp_rule_code !== data.customization.mkp_rule_code) {
              serviceDeactivate.push({ name: 'putMkpStateRule', params: {organization_id: data.shipper_id, rule_id: account.customization.mkp_rule_code, method: 'deactivate' }})
              serviceActivate.push({ name: 'putMkpStateRule', params: {organization_id: data.shipper_id, rule_id: data.customization.mkp_rule_code, method: 'active' }})
            }
            if (account.customization.own_rule_code !== data.customization.own_rule_code) {
              serviceDeactivate.push({ name: 'putSellerStateRule', params: {organization_id: data.shipper_id, shipper_id: data.customization.shipper_id, rule_id: account.customization.own_rule_code, method: 'deactivate' }})
              serviceActivate.push({ name: 'putSellerStateRule', params: {organization_id: data.shipper_id, shipper_id: data.customization.shipper_id, rule_id: data.customization.own_rule_code, method: 'active' }})
            } 
            if (serviceDeactivate.length > 0) this.$store.dispatch('fetchMultipleServices', { services: serviceDeactivate, onSuccess: onSuccessDeactivate })
            else {
              // si no hay cambio en las reglas solo se modifican los demas campos
              const {onError, ...rest} = fecth
              this.$store.dispatch('fetchService', {...rest})
            }
          }
        }
      })
    },
    getServicesRules(data, method) {
      return [
        { name: 'putMkpStateRule', params: {organization_id: data?.shipper_id, rule_id: data?.customization?.mkp_rule_code, method }},
        { name: 'putSellerStateRule', params: {organization_id: data?.shipper_id, shipper_id: data?.customization?.shipper_id, rule_id: data?.customization?.own_rule_code, method }}
      ]
    }
  }
}
</script>

<style lang='scss'>
.account-button {
  top: 0rem !important;
  right: 2rem !important;
  position: relative !important;
  z-index: 9;
  display: flex;
  gap: 0.5rem;
}
.custom-badge {
  background-color: #6CBD1A;
  color: white;
  font-size: 8px;
  padding: 5px 5px 4px 5px;
  border-radius: 5px;
  margin-left: 3px;
}
@media (max-width: 648px) {
  .tabs-multiAccounts .tabs {
    margin-top: 4rem;
  }
  .account-button {
    right: 0 !important;
    button {
      padding: 0.5rem 0.9rem;
    }
  }
}
</style>
