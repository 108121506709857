<template>
  <b-modal
    id="modalCreateAccount"
    :title="editing ? `Cuenta ${formTab.code}` : 'Añadir courier y nueva cuenta'"
    ok-variant="warning"
    modal-class="dialog-900 modal-background--multi-color"
    @close="onClose"
    @show="onShow"
    ok-only
  >
    <div>
      <p>Por favor, proporcione informacion detallada del {{editing ? 'courier y la cuenta a editar' : 'nuevo courier y la cuenta a crear'}}.</p>
      <form-render
        :fields.sync="fields"
        :form.sync="form"
        :key="keyFormRenderAccount"
        ref="formRenderAccount"
        containerButtonsClass="col-sm-12 col-lg-4 container-button"
        @send="onAccept"
      >
        <template #space />
        <template #loading>
          <p class="my-3"><b-spinner small class="mr-1"></b-spinner>Obteniendo datos...</p>
        </template>
        <template #credentials_title>
          <h2 class="card-title mb-0">Credenciales couriers</h2>
        </template>
        <template #no_credentials>
          <b-alert class="mb-0 p-1" show variant="info"><span class="text-primary font-weight-normal"><feather-icon icon="InfoIcon"/> No existen credenciales de couriers para editar manualmente.</span></b-alert>
        </template>
      </form-render>
    </div>
    <template #modal-footer>
      <b-button variant="warning" @click.prevent="ok()" :disabled="loading">
        <b-spinner v-show="loading" small></b-spinner>
        {{editing ? $t('Editar cuenta') : $t('Agregar')}}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import CarriersService from '@/views/modules/configuration/carriers/carriers.service'

export default {
  name: 'modal-create-multiaccount',
  props: ['formTab', 'type', 'contractType'],
  data() {
    return {
      form: {},
      fields: [],
      carrierCode: null,
      fieldFix: [],
      editing: false,
      keyFormRenderAccount: 1,
      CarrierService: new CarriersService(this),
      rowsRules: [],
      isDistributions: null,
      credTitle: {name: 'credentials_title', useSlot: true, containerClass: 'col-sm-12 container-info pt-1 border-top mt-1', isDynamic: true},
      carriersDistribution: [],
      carriersPasarela: [],
      fixCarrierResp: [],
      loading: false,
      optionsType: [
        { id: 1, text: 'Ingreso manual de datos', value: false},
        { id: 2, text: 'Activar cuenta de Envíame', value: true}
      ],
      currentSelects: {
        type: null,
        carrier: null
      }
    }
  },
  computed: {
    ...mapGetters({
      carriers: 'getCarriers',
      rules: 'getShipperRules',
      carrier: 'getCarrier',
      organizationsCarriers: 'getOrganizationCarrierContract',
      getIndexTab: 'getIndexTabMultiAccounts',
      generalLoading: 'getLoading'
    })
  },
  watch: {
    generalLoading: {
      handler () {
        this.loading = !!this.generalLoading.updateCarriersAccount || !!this.generalLoading.createCarriersAccount
      },
      deep: true
    },
    contractType() {
      const carrierIndex = this.m_getElementIndex(this.fields, 'name', 'carrier_name')
      this.fields[carrierIndex].change = this.selectCourier
      // if (this.contractType?.toLowerCase() !== 'distribucion') this.fields[carrierIndex].change = this.selectCourier
      // else delete this.fields[carrierIndex].change
      this.setCarrierOptions()
    },
    carriers() {
      if (this.fixCarrierResp?.length === 0) this.fixCarrierRes()
      this.setCarrierOptions()
    },
    formTab() {
      if (this.type?.toLowerCase() === 'detail') return
      const hasCredentials = this.m_getElementIndex(this.fields, 'name', 'credentials_title')
      if (!this.formTab.id) this.fields = [...this.fieldFix]
      else if (hasCredentials !== -1) {
        this.removeDynamicFields()
        this.form = this.m_cleanObjFrom(this.form, 'credentials_title')
      }
      this.initializeForm()
    },
    fixCarrierResp () {
      this.carriersDistribution = this.fixCarrierResp.filter(carr => carr.status.name.toLowerCase() === 'habilitado')
      this.carriersPasarela = this.fixCarrierResp.filter(carr => carr.status.name.toLowerCase() === 'pasarela')
      this.carriersPasarela.push(...this.carriersDistribution)
      this.setCarrierOptions()
    },
    organizationsCarriers() {
      const organizationField = this.fields.find(field => field.name === 'organization_id')

      organizationField.options = this.organizationsCarriers.map(organization => ({
        text: organization.name,
        id: organization.id,
        ...organization
      }))
    }
  },
  mounted() {
    this.setInitialData()
    if (this.carriers?.rows?.length > 0) this.setCarrierOptions()
  },
  methods: {
    onShow() {
      this.setInitialData()
      if (this.carriers?.rows?.length > 0) this.setCarrierOptions()
      const find = this.fields?.find((field) => field.name === 'actionType')
      if (this.getIndexTab === 0 && !find) {
        this.fieldFix = [...this.fields, { fieldType: 'FieldRadio', name: 'actionType', align: 'h', options: this.optionsType, change: this.changeType, containerClass: 'col-12 my-1', label: 'Seleccione la acción que desea tomar con respecto a la cuenta que se creará a continuación :', validation: 'required'}]
      }
    },
    setInitialData() {
      this.fieldFix = [
        { fieldType: 'FieldInput', name: 'code', label: 'Nombre de cuenta', containerClass: 'col-sm-12 col-md-12 container-info', placeholder: 'Ingrese nombre de la cuenta', validation: 'required' },
        {
          fieldType: 'FieldSelect', name: 'carrier_name', label: 'Courier', containerClass: 'col-sm-12 container-info col-md-6', validation: 'required',
          placeholder: 'Seleccione courier', disabled: false },
        { fieldType: 'FieldInput', name: 'contract_type', label: 'Tipo de contrato envíame', containerClass: 'col-md-6  col-sm-12', disabled: true },
        { fieldType: 'FieldDatepicker', label: 'Fecha de expiracion', name: 'validity_date', containerClass: 'col-sm-12 col-md-6 col-lg-6 container-info', validation: '', propsVCalendar: { 'min-date': new Date() }, clearable: true },
        { fieldType: 'FieldCheckbox', name: 'is_preferential', containerClass: 'col-sm-12 col-md-6 container-info pt-2', options: [{ text: 'Activar cuenta como preferencial', id: true}] }
      ]

      this.fields = [...this.fieldFix]

      if (this.fixCarrierResp.length) this.setCarrierOptions()
      this.keyFormRenderAccount++
    },
    initializeForm() {
      this.editing = !!this.formTab.id
      this.fields.find(field => field.name === 'carrier_name').disabled = this.type === 'detail'
      this.isDistributions = this.contractType?.toLowerCase() === 'distribucion'
      this.updateFormWithTabData()
    },
    updateFormWithTabData() {
      this.form = {
        ...this.formTab,
        contract_type: this.contractType
      }
      if (this.editing) {
        if (this.formTab.shipper) {
          this.formTab.organization_id = {
            ...this.formTab.shipper,
            text: this.formTab.shipper?.name
          }
        }
        this.form = {
          ...this.formTab,
          ...this.formTab.credentials,
          carrier_name: {...this.formTab.carrier, text: this.formTab.carrier.name},
          is_active: this.formTab.is_active ? 'Activa' : 'Inactiva'
        }

        if (this.m_getElementIndex(this.fields, 'name', 'is_active') === -1) {
          const carrierIndex = this.m_getElementIndex(this.fields, 'name', 'validity_date')
          this.fields.splice(carrierIndex, 0,
            { fieldType: 'FieldInput', label: 'Estado de cuenta', name: 'is_active', containerClass: 'col-sm-12 col-md-6 container-info', disabled: true }
          )
        }    

        const credKeys = Object.keys(this.formTab.credentials)
        if (this.type === 'edit' && this.contractType?.toLowerCase() === 'pasarela' && !!credKeys.length) {
          const credentials = credKeys.map(key => ({ name: key, label: key }))
          this.fields.push(this.credTitle, ...this.generateInputsForm(credentials))
        }
      }
    },
    setCarrierOptions() {
      if (this.fixCarrierResp.length === 0) {
        this.fixCarrierRes()
        return
      }
      const carrierField = this.fields.find(field => field.name === 'carrier_name')
      carrierField.options = this.contractType?.toLowerCase() === 'distribucion' ? this.carriersDistribution : this.carriersPasarela
    },
    selectCourier(form, value) {
      this.currentSelects.carrier = value
      const expDateIndex = this.m_getElementIndex(this.fields, 'name', 'credentials_title')
      if (!value) {
        this.form = this.m_cleanObjFrom(this.form, 'validity_date')
        this.fields.splice(expDateIndex)
        return
      }
      if (this.contractType?.toLowerCase() === 'distribucion' && this.currentSelects?.type?.id === 1) this.getCredentialsData()
      if (this.contractType?.toLowerCase() === 'pasarela') this.getCredentialsData()
    },
    fixCarrierRes () {
      this.fixCarrierResp = this.carriers.rows.map(carrier => ({
        text: `[${carrier.carrier_code}] ${carrier.carrier_name}`,
        id: carrier.carrier_code,
        ...carrier
      }))
    },
    getCredentialsData() {
      const carrier_id = this.form.carrier_name.carrier_id

      const carrierIndex = this.fields.findIndex(fieldsData => fieldsData.name === 'carrier_name')
      this.fields[carrierIndex].disabled = true

      const credFieldIndex = this.fields.findIndex(fieldsData => fieldsData.name === 'credentials_title')
      if (credFieldIndex !== -1) this.fields.splice(credFieldIndex)

      this.fields.push({name: 'loading', useSlot: true, containerClass: 'col-12'})

      this.CarrierService.callService('getCarrier', {carrier_id }, {carrier_id })
        .then(resp => {
          this.setCarrierCrendentials(resp.data)
        })
        .catch(err => {
          console.error(err)
          this.form.carrier_name = null
          this.$alert(this.$t('msg-problema-actualizar', { code: err }))
          this.fields.splice(this.fields.findIndex(fieldsData => fieldsData.name === 'loading'), 1)
          this.keyFormRenderAccount++
        }).finally(() => {
          this.fields[carrierIndex].disabled = false
        })
    },
    removeDynamicFields() {
      this.fields = this.fields.filter(field => !field.isDynamic)
    },
    setCarrierCrendentials(value) {
      // Hacer una copia de this.fields
      const credFieldIndex = this.fields.findIndex(fieldsData => fieldsData.name === 'credentials_title')
      const loadingIndex = this.fields.findIndex(fieldsData => fieldsData.name === 'loading')

      //Generar nuevos input
      const updatedFields = [this.credTitle]
      if (value?.account_params.length) {
        updatedFields.push(...this.generateInputsForm(value.account_params))
      } else {
        updatedFields.push({name: 'no_credentials', containerClass: 'col-sm-12', useSlot: true, isDynamic: true})
      }

      //Actualizar this.fields con la copia actualizada
      this.fields.splice(loadingIndex, 1)
      if (credFieldIndex === -1) {
        this.fields.push(...updatedFields)
      } else {
        this.fields.splice(credFieldIndex + 1, 0, ...updatedFields)
      }
    },
    generateInputsForm(value) {
      const inputs = []
      value.forEach(field => {
        inputs.push({
          fieldType: 'FieldInput',
          name: field.name,
          placeholder: field.example || null,
          label: field.name,
          clearable: true,
          validation: 'required',
          containerClass: 'col-sm-12 col-md-6 container-info',
          isDynamic: true,
          noZIndex: true
        })
      })
      return inputs
    },
    ok(e) {
      this.$refs.formRenderAccount.checkForm()
    },
    onAccept(form) {
      const { actionType, carrier_name, code, contract_type, organization_id, is_preferential, account_create_in, validity_date: validityDate, ...rest } = form
      delete rest.is_active
      const queryParams = {
        code,
        contract_type: contract_type.toLowerCase(), //?? this.isDistributions ? 'distribucion' : 'pasarela',
        data: {},
        carrier_code: carrier_name.carrier_code || carrier_name.code,
        shipper_id: parseInt(this.$route.params.id),
        is_preferential: is_preferential ?? false, //Esta valor debe cambiar a is_preferential
        organization_id: this.isDistributions ? 10 : parseInt(this.$route.params.id),
        validity_date: !!validityDate ? this.$options.filters.dbDateUTCToGMTlocal(validityDate, 'YYYY-MM-DD HH:mm:ss') : null,
        customization: !this.isDistributions ? { 'default-account': true } : {}, //esto solo deberia de enviarse si es pasarela
        credentials: { ...rest } //esto solo deberia de enviarse si es pasarela
        //organization_id -> Mas adelante debe tenerse encuenta para los casos de seller de un mkp, ya que no puede ser 10 el padre
      }
      // if (!this.isDistributions) {
      //   queryParams.customization = { 'default-account': true }
      //   delete rest.is_active
      //   queryParams.credentials = { ...rest }
      // }
      const headers = {
        'x-carrier' : carrier_name.carrier_code || carrier_name.code
      }     
      if (this.editing) {
        this.$store.dispatch('fetchService', {
          name: 'updateCarriersAccount',
          queryParams,
          params: { account_id: this.form.id },
          headers,
          onSuccess: this.succesCreateAccount,
          onError: () => this.loading = false
        })
      } else {
        this.$store.dispatch('fetchService', {
          name: 'createCarriersAccount', queryParams,  headers,
          onSuccess: this.succesCreateAccount,
          onError: () => this.loading = false
        })
      }
    },
    succesCreateAccount() {
      this.$bvModal.hide('modalCreateAccount')
      if (this.type?.toLowerCase() === 'creation') {
        const text = `
          <div class="text-center">
            <img src="${require('@/assets/images/icons/distributionImage.svg')}" alt="star" class="mb-2 h-50 w-50" />
            <h3>¡El courier ${this.form.carrier_name.carrier_name} se ha incorporado con éxito a la empresa!</h3>
            <p> Hemos creado la cuenta <span class="text-primary">“${this.form.code}”</span> para que puedas comenzar a optimizar tus operaciones.</p>
            ${this.form.is_preferential ? '<p>La hemos configurado como preferencial y está lista para ser utilizada.</p>' : ''}
          </div>
        `
        const title = 'Courier y cuenta creada'
        this.$success(text, null, title, null, 'none', 730, 'btn btn-outline-warning')
      } else {
        this.$success(this.$t('msg-exito-guardar'))
      }      
      this.onClose()
      const queryParams = {
        shipper_id : this.$route.params.id,
        contract_type: this.contractType.toLowerCase(),
        page: 1,
        limit: 10
      }
      this.$store.dispatch('fetchService', {
        name: 'getCarriersOrganizationShippers',
        queryParams
      })
    },
    onClose() {
      this.isDistributions = null
      this.currentSelects = {
        type: null,
        carrier: null
      }
    },
    changeType(_, value) {
      this.currentSelects.type = value
      if (!!this.currentSelects.carrier && value?.id === 1) this.selectCourier(_, this.currentSelects.carrier)
      else if (value?.id === 2) { 
        this.removeDynamicFields()
      }
    }
  }
}
</script>